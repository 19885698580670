<template>
	<div class="flex-column full-height bg-gray-light">
		<div class="pb-10">
			<div class="bg-white pa-10">
				<DatePicker :date="item_date" @click="setDate($event)" ></DatePicker>
			</div>
			<div
				class="justify-space-between bg-white"
			>
				<label class="top-line flex-1 pa-10">
					<input
						type="file"
						@change="setFile"
						accept=".xls,.xlsx"
						class="hidden"
						ref="file"
					/>
					<template
						v-if="item.file"
					>
						{{ item.file.name}}
					</template>
					<template
						v-else
					>
						엑셀 파일을 선택하세요
					</template>
				</label>

				<button
					@click="postSettlementExcel"
					class="btn-inline btn-primary"
					:disabled="!item.file"
				>조회</button>
			</div>
		</div>
		<div class="full-height overflow-y-auto">
			<ul
				v-if="account_list.length > 0"
			>
				<li
					class=" bg-white pa-10 position-relative"
				>
					<div class="justify-space-between items-center">
						<span @click="setAll">
							<v-icon
								v-if="item_all"
								class="color-blue"
							>mdi-checkbox-marked</v-icon>
							<v-icon
								v-else
							>mdi-checkbox-blank-outline</v-icon>
							전체 {{ account_list.length | makeComma }}건
						</span>
						<span> {{ total_amount | makeComma }} 원</span>
					</div>
				</li>
				<li
					v-for="(item, index) in account_list"
					:key="'item_' + index"
					class=" bg-white mtb-10 pa-10 position-relative"
					:class="{ 'cursor-pointer': item.is_account }"
				>
					<div
						v-if="!item.is_account"
						class="bg-layer bg-white"
					></div>
					<div
						class="justify-space-between items-center under-line pb-10"
						@click="setItemAll(item)"
					>
						<div>
							<button
								v-if="item.is_account"
							>
								<v-icon
									v-if="item.is_all"
									class="color-blue"
								>mdi-checkbox-marked</v-icon>
								<v-icon
									v-else
								>mdi-checkbox-blank-outline</v-icon>
							</button>
						</div>
						<span :class="{ 'color-red': !item.is_account }">{{ item.is_account ? item.account_id : '가맹점 없음'}}</span>
					</div>
					<div class="mt-10 justify-space-between items-center">
						<span>상점명</span>
						<span>{{ item.E }}</span>
					</div>
					<div class="mt-10 justify-space-between items-center">
						<span>정산금액</span>
						<span>{{ item.Z | makeComma }}원</span>
					</div>
					<div class="mt-10 justify-space-between items-center">
						<span>차감금액</span>
						<span><input v-model="item.minus" type="number" class="box pa-5-10 text-right" placeholder="차감금액을 입력하세요" :rules="[$rules.max(item, 'minus', 9)]" /></span>
					</div>
					<div class="mt-10 justify-space-between items-center">
						<span>상점 연락처</span>
						<span>{{ item.AC }}</span>
					</div>
				</li>
			</ul>
			<Empty
				v-else
			></Empty>
		</div>
		<div class="mt-auto pt-10 bg-gray-light">
			<div class="justify-space-between pa-10 bg-white">
				<span> 선택 {{ select_count | makeComma }}건</span>
				<span>{{ select_amount | makeComma}}원</span>
			</div>
			<div>
				<button
					@click="onCalculate"
					class="btn btn-identify"
					:disabled="is_save"
				>선택 등록</button>
			</div>
		</div>

		<PopupConfirm
			v-if="is_on_calculate"
			@click="postSettlement"
			@cancel="offCalculate"
			class="text-center"
		>
			<template
				v-slot:title
			>가맹점 정산 요청</template>
			<template
				v-slot:main-txt
			>{{ item_date }}</template>

			<template
				v-slot:sub-txt
			><br/>선택된 가맹점의 정산요청을 진행하시겠습니까? <br/> 정산금액은 다음날 지급됩니다. </template>
		</PopupConfirm>
	</div>
</template>

<script>
import Empty from "../Layout/Empty";
import PopupConfirm from "../Layout/PopupConfirm";
import DatePicker from "../../components/DatePicker";
export default {
	name: 'FranchiseeList'
	, components: {DatePicker, PopupConfirm, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '가맹점 정산'
				, top: false
				, title: true
				, bottom: false
			}
			, items: []
			, item: {}
			, items_file: []
			, items_account: []
			, item_all: true
			, is_on_calculate: false
			, item_date: this.$route.params.date ? this.$route.params.date : this.$date.getToday('-')
		}
	}
	, computed: {
		account_list: function(){
			return this.items_file.filter( (item) => {
				if(item.A){

					this.items_account.filter( (account) => {

						if(item.F == account.shop_name){
							item.is_account = true
							if(this.item_all){
								this.$set(item, 'is_all', true)
							}
							item.shop_name = account.shop_name
							item.account_id = account.account_id
							item.virtual_account = account.virtual_account
							item.shop_uid = account.shop_uid

							return account
						}
					})

					return item
				}
			})
		}
		, items_account_request: function(){

			let t = []
			this.account_list.filter( (item) => {
				if(item.is_all){
					t.push({
						account_id: item.account_id
						, amount: item.Z
						, minus: item.minus
						, address: item.virtual_account
						, is_all: item.is_all
						, shop_uid: item.shop_uid
					})
				}
			})
			// console.log(t)
			return t
		}
		, select_count: function(){
			let t = 0
			t = this.items_account_request.length
			return t
		}
		, select_amount: function(){
			let t = 0
			this.account_list.filter( (item) => {
				console.log(item)
				if(item.is_account && item.is_all){
					t += Number(item.Z)
				}
			})
			return t
		}
		, total_amount: function(){
			let t = 0
			this.account_list.filter( (item) => {
				t += Number(item.Z)
			})
			return t
		}
		, is_save: function(){
			let t = true
			for(let i = 0; i < this.items_account_request.length; i++){
				if(this.items_account_request[i].is_all){
					t = false
					break
				}
			}
			return t
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'manager/getWalletHistory'
					,data: {

					}
				})

				if(result.success){
					this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSettlementExcel: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.post_calculate_excel
					,data: {
						file: this.item.file
					}
					, multipart: true
				})

				if(result.success){
					this.items_file = result.data.file_data
					this.items_account = result.data.account_data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.$refs.file = ''
			}
		}
		, setFile: function(e){

			let file = e.target.files[0]

			this.$set(this.item, 'file', file)
		}
		, onCalculate: function(){
			this.is_on_calculate = true
		}
		, offCalculate: function(){
			this.is_on_calculate = false
		}
		, postSettlement: async function(){

			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.post_settlement
					,data: {
						date: this.item_date
						, list: JSON.stringify(this.items_account_request)
					}
				})

				if(result.success){
					this.toList()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setDate: function(date){
			this.item_date = date
		}
		, toList: function(){
			this.$bus.$emit('to', { name: 'CalculateList', params: {date: this.item_date}})
		}
		, setAll: function(){
			this.item_all = !this.item_all
			this.account_list.filter( (item) => {
				console.log(item)
				item.is_all = this.item_all
			})
		}
		, setItemAll: function(item){
			item.is_all = !item.is_all
			if(!item.is_all){
				this.item_all = false
			}
			let t = false
			this.account_list.filter( (item) => {
				if(!item.is_all){
					t = true
				}
			})
			if(!t){
				this.item_all = true
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		// this.getData()
	}
	, watch: {

	}
}
</script>